.privacy-policy-main {
    background-color: #0a0a0a;
    color: #fff;
    padding: 30px;
    max-width: 900px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
  }
  
  .privacy-policy-title {
    color: #8a2be2;
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
  }
  
  .privacy-policy-section {
    margin-bottom: 20px;
  }
  
  .privacy-policy-section-title {
    font-size: 1.5rem;
    color: #00f7ff;
    margin-bottom: 10px;
  }
  
  .privacy-policy-section ul {
    list-style-type: square;
    margin-left: 20px;
  }
  
  .privacy-policy-section p {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  a {
    color: #00f7ff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  