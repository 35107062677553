/* Home.css */

.screens-home-main {
    text-align: center;
    padding: 20px;
}

.hero-section {
    padding: 100px 20px;
    background: linear-gradient(45deg, #0d0d0d, #1a1a2e);
    box-shadow: 0 0 30px rgba(0, 255, 255, 0.3);
    border-radius: 25px;
}

.hero-title {
    font-size: 4rem;
    color: #00f7ff;
    text-shadow: 0 0 20px #00f7ff, 0 0 40px #8a2be2;
    margin-bottom: 10px;
}

.hero-subtitle {
    font-size: 1.5rem;
    color: #cccccc;
    margin-bottom: 30px;
}

.cta-button {
    background-color: transparent;
    border: 2px solid #00f7ff;
    padding: 10px 30px;
    color: #00f7ff;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.4s ease;
}

.cta-button:hover {
    background-color: #00f7ff;
    color: #0a0a0a;
    box-shadow: 0 0 20px #00f7ff, 0 0 40px #8a2be2;
    transform: scale(1.1);
}

.about-section, .services-section, .contact-section {
    padding: 60px 20px;
    border-radius: 25px;
}

.about-section h2,
.services-section h2,
.contact-section h2 {
    font-size: 2.5rem;
    color: #8a2be2;
    text-shadow: 0 0 15px #8a2be2;
    margin-bottom: 20px;
}

.service-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
}

.service-card {
    background-color: #1a1a2e;
    padding: 20px;
    width: 250px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(138, 43, 226, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card h3 {
    color: #00f7ff;
    margin-bottom: 10px;
}

.service-card p {
    color: #cccccc;
}

.service-card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 25px #00f7ff, 0 0 50px #8a2be2;
}

.contact-section {
    background-color: #0d0d0d;
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.2);
}
